import React from "react";
import OurValuesComponent from "components/our_values_component";
import CTABar2 from "components/cta_bar_2";
import LargeHeroBanner from "components/large_hero_banner";

const ServiceCard = ({ title, description, thumbnail_image, path }) => {
  const navigate = () => {
    window.location.href = path;
  };
  return (
    <div
      className=""
      onClick={navigate}
      style={{
        cursor: "pointer",
        // border: "1px solid #D9963D",
        borderRadius: "40px",
        // backgroundColor: "#D9963D",
      }}
    >
      <div className="service-layout2" onClick={navigate}>
        <div style={{ textAlign: "center" }} className="service-layout5">
          <img
            src={`/${thumbnail_image}`}
            alt="values"
            className="img-fluid width-100 mb-3"
            style={{
              height: "200px",
              objectFit: "cover",
              borderRadius: "15px",
              // filter: "grayscale(100%)",
            }}
          />
          {/* <div className="item-icon">
                    <i className="flaticon-insurance-1" />
                </div> */}
          {/* <div style={{
                      backgroundImage: `/${thumbnail_image}`, 
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      maxHeight: "250px",
                      minHeight: "250px",
                      height: "250px"
                    }} alt="service" /> */}
        </div>
        <h3 className="title title-medium size-xs color-dark-accent hover-color-accent text-center">
          {title}
        </h3>
        {/* <p style={{cursor: "pointer"}}>{description}</p> */}
        {/* <a href={path} className="btn-ghost btn-icon icon-right size-xs radius-4">Read More<i className="fas fa-caret-right" /></a> */}
      </div>
    </div>
  );
};

const OurValuesPage = ({ data }) => {
  return (
    <>
      <div id="header-area-space" className="slider-area bg-light-primary">
        <LargeHeroBanner bgImage={"/img/banner/our_values.jpg"}>
          <h2>
            <strong>Our Values</strong>
          </h2>
          <br />
          <h2>The team at Finstead Risk Solutions</h2>{" "}
          <h2>
            <strong>pride ourselves</strong> in our{" "}
          </h2>
          <h2> working environment</h2>
          <br />
          {/* <a
            href="/contact"
            className="btn-fill color-light radius-4 btn-icon size-sm icon-right mt-2"
          >
            Call Now
            <i className="fas fa-angle-right" />
          </a> */}
        </LargeHeroBanner>
      </div>

      <CTABar2 />
      <br />
      <br />
      <br />
      <div className="w-100 text-center mb-4">
        <h2
          style={{ fontWeight: "300" }}
          className="title title-regular size-xs mt-5"
        >
          Our Values
        </h2>
      </div>
      <br />

      <div className="container mt-5 pt-5">
        <div
          className="rc-carousel nav-control-custom"
          data-loop="true"
          data-items={4}
          data-margin={30}
          data-autoplay="true"
          data-autoplay-timeout={5000}
          data-smart-speed={2000}
          data-dots="false"
          data-nav="false"
          data-nav-speed="false"
          data-r-x-small={2}
          data-r-x-small-nav="false"
          data-r-x-small-dots="false"
          data-r-x-medium={2}
          data-r-x-medium-nav="false"
          data-r-x-medium-dots="false"
          data-r-small={2}
          data-r-small-nav="false"
          data-r-small-dots="false"
          data-r-medium={4}
          data-r-medium-nav="false"
          data-r-medium-dots="false"
          data-r-large={4}
          data-r-large-nav="false"
          data-r-large-dots="false"
        >
          <ServiceCard
            {...{
              path: "",
              title: "Transparency and Integrity",
              description: "",
              thumbnail_image: "img/values/transparency_and_integrity.jpg",
            }}
          />
          <ServiceCard
            {...{
              path: "",
              title: "Proactive management",
              description: "",
              thumbnail_image: "img/values/proactive_management.jpg",
            }}
          />
          <ServiceCard
            {...{
              path: "",
              title: "Innovation and Integrity",
              description: "",
              thumbnail_image: "img/values/innovation_and_integrity.jpg",
            }}
          />
          <ServiceCard
            {...{
              path: "",
              title: "Reliability",
              description: "",
              thumbnail_image: "img/values/reliability.jpg",
            }}
          />
        </div>
        <br />
        <br />
        <br />
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <h3
              className="title"
              style={{ fontWeight: "300", color: "#D9963D" }}
            >
              <strong>Transparency</strong>
            </h3>
            <p style={{ fontWeight: "300" }}>
              You can trust that we will be entirely transparent in our
              communication of recommended solutions and remuneration
            </p>
          </div>
          <div className="col-xs-12 col-sm-6">
            <h3
              className="title"
              style={{ fontWeight: "300", color: "#D9963D" }}
            >
              <strong>Innovation and Integrity</strong>
            </h3>
            <p style={{ fontWeight: "300" }}>
              We are dedicated to consistently working on our own performance so
              that we can assist you in yours. We will continue to seek
              innovative solutions to your insurance needs
            </p>
          </div>
          <div className="col-xs-12 col-sm-6">
            <h3
              className="title"
              style={{ fontWeight: "300", color: "#D9963D" }}
            >
              <strong>Proactive Management</strong>
            </h3>
            <p style={{ fontWeight: "300" }}>
              We ensure that claims are pro-actively managed. We aim to
              anticipate each client’s needs and that we are consistently
              delivering the best possible claim outcomes.
            </p>
          </div>
          <div className="col-xs-12 col-sm-6">
            <h3
              className="title"
              style={{ fontWeight: "300", color: "#D9963D" }}
            >
              <strong>Reliability</strong>
            </h3>
            <p style={{ fontWeight: "300" }}>
              We aim to provide consistent, reliable service. We continually
              strive to ensure that Finstead Risk Solutions is a company that
              you can count on regardless of the economic climate.
            </p>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default OurValuesPage;
