import React from "react";
import Layout from "components/layout";
import OurValuesPage from "./paths/our_values_page";
import FsAnalytics from "components/fs_analytics";

const Page = () => {
  return (
    <Layout>
      {/* Finstead Analytics */}
      <FsAnalytics />
      <OurValuesPage />
    </Layout>
  );
};
export default Page;
